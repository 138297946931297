html {
  background: var(--bg-color);
  overflow: hidden;
  position: fixed;
  overscroll-behavior: none;
  touch-action: manipulation;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--bg-color) !important;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/static/fonts/Montserrat-SemiBold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Boston';
  src: url('/static/fonts/Boston-Light.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Boston';
  src: url('/static/fonts/Boston-Bold.otf');
  font-weight: 600;
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  html {
    background: var(--bg-color);
    transform: rotate(-90deg);
    transform-origin: left top;
    height: 100vw;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@media screen and (min-width: 1138px) and (max-height: 712px) {
  html {
    transform: scale(0.84) translateY(-70px);
  }
}

@media screen and (min-width: 2560px) {
  html {
    transform: scale(1.65) translateY(300px);
  }
}
